// Delivery.jsx
import React, { useState } from "react";
import { Button, Grid, Box } from "@mui/material";

const Delivery = (props) => {
  const { platForm } = props;
  const [complete, setComplete] = useState(false);
  const handlePayment = () => {
    console.log(`扣款:${platForm}`);
    // 在這裡處理付款邏輯

    //支付成功
    setComplete(true);
  };
  return !complete ? (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p className="sidebar_btn_text text-sold_out_gray text-center">
        {platForm === "uber" ? "UberEats 付款" : "FoodPanda 付款"}
      </p>
      <Grid container sx={{ marginTop: 2, fontWeight: "bold", width: "100%" }}>
        <Button
          variant="contained"
          fullWidth
          size="large"
          sx={{
            backgroundColor: "#FFF",
            height: "60px",
            borderRadius: 2,
            padding: "8px 16px",
          }}
          onClick={handlePayment}
        >
          <p className="text_default_style text-dark_red font-[700]">扣款</p>
        </Button>
      </Grid>
    </Box>
  ) : (
    <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
      結帳完成
    </h1>
  );
};

export default Delivery;
