import React, { useState } from "react";
import { Dialog, DialogContent, TextField, Button, Grid } from "@mui/material";
import dayjs from "dayjs";

function Member({ open, handleClose }) {
  const [formData, setFormData] = useState({
    name: "",
    tableOrOrderNumber: "",
    phoneNumber: "",
    address: "",
    pickupTime: "",
    note: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    console.log("Saved data:", formData);
    handleClose();
  };

  // 快選按鈕的處理
  const handleQuickSelect = (minutes) => {
    const newPickupTime = dayjs()
      .add(minutes, "minute")
      .format("YYYY-MM-DDTHH:mm");
    setFormData((prevState) => ({
      ...prevState,
      pickupTime: newPickupTime,
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 4, // 設定對話框的圓角
          backgroundColor: "#E2E2E2", // 設定對話框背景顏色
          padding: "32px 24px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              姓名
            </p>
            <TextField
              name="name"
              fullWidth
              value={formData.name}
              onChange={handleChange}
              InputProps={{
                sx: {
                  // 这里可以修改 .MuiInputBase-input 的样式
                  borderRadius: 4, // 設定圓角
                  fontSize: "20px",
                  color: "#797777",
                  fontWeight: 700,
                  backgroundColor: "#FFF",
                  "&::placeholder": {
                    color: "#C0C0C0", // 自定义占位符颜色
                  },
                  "&::-webkit-calendar-picker-indicator": {
                    display: "none", // 隐藏日历图标
                  },
                },
              }}
              sx={{
                borderRadius: 4, // 設定圓角
                backgroundColor: "white", // 設定背景顏色為白色
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none", // 去除邊框
                  },
                  "&:hover fieldset": {
                    border: "none", // 去除懸停時邊框
                  },
                  "&.Mui-focused fieldset": {
                    border: "none", // 去除獲得焦點時邊框
                  },
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              桌號單號
            </p>
            <TextField
              name="tableOrOrderNumber"
              fullWidth
              value={formData.tableOrOrderNumber}
              onChange={handleChange}
              InputProps={{
                sx: {
                  // 这里可以修改 .MuiInputBase-input 的样式
                  borderRadius: 4, // 設定圓角
                  fontSize: "20px",
                  color: "#797777",
                  fontWeight: 700,
                  backgroundColor: "#FFF",
                  "&::placeholder": {
                    color: "#C0C0C0", // 自定义占位符颜色
                  },
                  "&::-webkit-calendar-picker-indicator": {
                    display: "none", // 隐藏日历图标
                  },
                },
              }}
              sx={{
                borderRadius: 4, // 設定圓角
                backgroundColor: "white", // 設定背景顏色為白色
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none", // 去除邊框
                  },
                  "&:hover fieldset": {
                    border: "none", // 去除懸停時邊框
                  },
                  "&.Mui-focused fieldset": {
                    border: "none", // 去除獲得焦點時邊框
                  },
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              手機號碼
            </p>
            <TextField
              name="phoneNumber"
              fullWidth
              value={formData.phoneNumber}
              onChange={handleChange}
              InputProps={{
                sx: {
                  // 这里可以修改 .MuiInputBase-input 的样式
                  borderRadius: 4, // 設定圓角
                  fontSize: "20px",
                  color: "#797777",
                  fontWeight: 700,
                  backgroundColor: "#FFF",
                  "&::placeholder": {
                    color: "#C0C0C0", // 自定义占位符颜色
                  },
                  "&::-webkit-calendar-picker-indicator": {
                    display: "none", // 隐藏日历图标
                  },
                },
              }}
              sx={{
                borderRadius: 4, // 設定圓角
                backgroundColor: "white", // 設定背景顏色為白色
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none", // 去除邊框
                  },
                  "&:hover fieldset": {
                    border: "none", // 去除懸停時邊框
                  },
                  "&.Mui-focused fieldset": {
                    border: "none", // 去除獲得焦點時邊框
                  },
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              地址
            </p>
            <TextField
              name="address"
              fullWidth
              value={formData.address}
              onChange={handleChange}
              InputProps={{
                sx: {
                  // 这里可以修改 .MuiInputBase-input 的样式
                  borderRadius: 4, // 設定圓角
                  fontSize: "20px",
                  color: "#797777",
                  fontWeight: 700,
                  backgroundColor: "#FFF",
                  "&::placeholder": {
                    color: "#C0C0C0", // 自定义占位符颜色
                  },
                  "&::-webkit-calendar-picker-indicator": {
                    display: "none", // 隐藏日历图标
                  },
                },
              }}
              sx={{
                borderRadius: 4, // 設定圓角
                backgroundColor: "white", // 設定背景顏色為白色
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none", // 去除邊框
                  },
                  "&:hover fieldset": {
                    border: "none", // 去除懸停時邊框
                  },
                  "&.Mui-focused fieldset": {
                    border: "none", // 去除獲得焦點時邊框
                  },
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              取餐時間
            </p>
            <TextField
              name="pickupTime"
              type="datetime-local"
              fullWidth
              InputProps={{
                sx: {
                  // 这里可以修改 .MuiInputBase-input 的样式
                  borderRadius: 4, // 設定圓角
                  fontSize: "20px",
                  color: "#797777",
                  fontWeight: 700,
                  backgroundColor: "#FFF",
                  "&::placeholder": {
                    color: "#C0C0C0", // 自定义占位符颜色
                  },
                  "&::-webkit-calendar-picker-indicator": {
                    display: "none", // 隐藏日历图标
                  },
                },
              }}
              value={formData.pickupTime}
              onChange={handleChange}
              sx={{
                borderRadius: 4, // 設定圓角
                backgroundColor: "white", // 設定背景顏色為白色
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none", // 去除邊框
                  },
                  "&:hover fieldset": {
                    border: "none", // 去除懸停時邊框
                  },
                  "&.Mui-focused fieldset": {
                    border: "none", // 去除獲得焦點時邊框
                  },
                },
              }}
            />
          </Grid>

          {/* 快選按鈕部分 */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              {" "}
            </p>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    onClick={() => handleQuickSelect(10)}
                    sx={{
                      borderRadius: "9999px",
                      backgroundColor: "#B0BEC5",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#8E8E8E",
                      },
                    }}
                  >
                    10分鐘
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    onClick={() => handleQuickSelect(15)}
                    sx={{
                      borderRadius: "9999px",
                      backgroundColor: "#B0BEC5",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#8E8E8E",
                      },
                    }}
                  >
                    15分鐘
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    onClick={() => handleQuickSelect(20)}
                    sx={{
                      borderRadius: "9999px",
                      backgroundColor: "#B0BEC5",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#8E8E8E",
                      },
                    }}
                  >
                    20分鐘
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    onClick={() => handleQuickSelect(30)}
                    sx={{
                      borderRadius: "9999px",
                      backgroundColor: "#B0BEC5",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#8E8E8E",
                      },
                    }}
                  >
                    30分鐘
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: "flex", alignItems: "center", gap: "8px" }}
          >
            <p className="min-w-[88px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
              備註
            </p>
            <TextField
              name="note"
              fullWidth
              multiline
              rows={4}
              value={formData.note}
              onChange={handleChange}
              InputProps={{
                sx: {
                  // 这里可以修改 .MuiInputBase-input 的样式
                  borderRadius: 4, // 設定圓角
                  fontSize: "20px",
                  color: "#797777",
                  fontWeight: 700,
                  backgroundColor: "#FFF",
                  "&::placeholder": {
                    color: "#C0C0C0", // 自定义占位符颜色
                  },
                  "&::-webkit-calendar-picker-indicator": {
                    display: "none", // 隐藏日历图标
                  },
                },
              }}
              sx={{
                borderRadius: 4, // 設定圓角
                backgroundColor: "white", // 設定背景顏色為白色
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none", // 去除邊框
                  },
                  "&:hover fieldset": {
                    border: "none", // 去除懸停時邊框
                  },
                  "&.Mui-focused fieldset": {
                    border: "none", // 去除獲得焦點時邊框
                  },
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            marginTop: "32px",
          }}
        >
          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={{
              backgroundColor: "#FFF",
              height: "80px",
              borderRadius: 7,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              padding: "8px 16px",
            }}
            onClick={() => handleClose()}
          >
            <p className="text_default_style text-dark_red font-[700]">儲存</p>
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default Member;
