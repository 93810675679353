
/**
 * Creates a reducer function that combines the provided reducers with the async reducers.
 */
const createReducer = ( ) => {} ;

/* Reset the redux store when user logged out */
/**
	if (action.type === 'user/userLoggedOut') {
		// state = undefined;
	}
	return combinedReducer(state, action);
*/

export default createReducer;
