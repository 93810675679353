import React, { useState } from "react";
import { Dialog, DialogContent, Button, Grid, Typography } from "@mui/material";
import Cash from "./Payments/Cash.tsx";
import CreditCard from "./Payments/CreditCard.tsx";
import Delivery from "./Payments/Delivery.tsx";
import LinePay from "./Payments/LinePay.tsx";

function Payment({ open, handleClose }) {
  // 狀態管理，用於切換顯示的內容
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0);

  // 定義金額數據
  const amounts = [
    { label: "訂單金額", value: "$1000" },
    { label: "已付金額", value: "$800" },
    { label: "找零金額", value: "$200" },
  ];

  // 定義支付方式
  const paymentMethods = [
    { title: "現金", id: 1 },
    { title: "LinePay", id: 2 },
    { title: "信用卡", id: 3 },
    { title: "Uber Eats", id: 4 },
    { title: "Food Panda", id: 5 },
  ];

  // 渲染金額顯示
  const renderAmountDisplay = () =>
    amounts.map((amount, index) => (
      <div key={index}>
        <h6 className="mb-[16px] text_default_style text-sold_out_gray font-[700]">
          {amount.label}
        </h6>
        <Typography
          variant="h6"
          sx={{
            backgroundColor: "#FFF",
            borderRadius: 4,
            height: "80px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <p className="system_login_title text-[#000] font-[700]">
            {amount.value}
          </p>
        </Typography>
      </div>
    ));

  // 渲染支付按鈕
  const renderPaymentButtons = () =>
    paymentMethods.map((method, index) => (
      <Button
        key={index}
        variant="outlined"
        fullWidth
        onClick={() => setSelectedPaymentMethod(method.id)}
        sx={{
          borderRadius: 4,
          border: "none",
          display: "flex",
          justifyContent: "center",
          bgcolor: "#838383",
          height: "64px",
          alignItems: "center",
          paddingX: "16px",
          marginBottom: "16px",
        }}
      >
        <p className="text_default_style text-[#FFF]">{method.title}</p>
      </Button>
    ));

  // 渲染選擇的支付方式畫面
  const renderPaymentMethodScreen = () => {
    const selectedMethod = paymentMethods.find(
      (method) => method.id === selectedPaymentMethod
    );

    // 判斷 selectedMethod 的值，並返回相應的組件
    switch (selectedMethod?.id) {
      case 1:
        return <Cash back={() => setSelectedPaymentMethod(0)} />; // 現金組件
      case 2:
        return <LinePay back={() => setSelectedPaymentMethod(0)} />; // LinePay 組件
      case 3:
        return <CreditCard />; // 信用卡組件
      case 4:
        return <Delivery platForm={"uber"} />; // UberEats 組件
      case 5:
        return <Delivery platForm={"panda"} />; // FoodPanda 組件
      default:
        return (
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            請返回
          </Typography>
        );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"md"}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 4,
          backgroundColor: "#E2E2E2",
          padding: "32px 24px",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Grid container direction={"row"} spacing={2}>
          <Grid
            item
            xs={7}
            onClick={() => selectedPaymentMethod && setSelectedPaymentMethod(0)}
            sx={{
              justifyContent: "center",
              padding: "16px",
              border: "none",
              borderRight: "1px solid #FFF",
            }}
          >
            {renderAmountDisplay()}
          </Grid>

          <Grid
            item
            xs={5}
            sx={{
              display: "grid",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {
              selectedPaymentMethod
                ? renderPaymentMethodScreen() // 顯示選擇的支付方式畫面
                : renderPaymentButtons() // 顯示支付按鈕
            }
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default Payment;
