// CreditCard.jsx
import React, { useState } from "react";

const CreditCard = () => {
  const [complete, setComplete] = useState(false);
  //callAPI???

  return !complete ? (
    <div>
      <h1 className="system_login_title text-[28px] text-dark_red flex items-center justify-center h-full w-full">
        請等待信用卡扣款
      </h1>
    </div>
  ) : (
    <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
      結帳完成
    </h1>
  );
};

export default CreditCard;
