import React, { useState, useEffect } from "react";
import { Button, Grid, Box, Typography } from "@mui/material";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import LeftSidebarLogo from "../../images/left_sidebar_logo.png";
import LeftSidebarOrder from "../../images/order.png";
import LeftSidebarHistory from "../../images/history.png";
import LeftSidebarInventory from "../../images/inventory.png";
import LeftSidebarSalesRecords from "../../images/sales_records.png";
import HomePageClassSchedule from "../../images/class_schedule.png";
import { cx } from "../../utils/cx.ts";
import SystemLoginPage from "../SystemLoginPage/SystemLoginPage.jsx";
function MainMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTime, setCurrentTime] = useState("");
  // const [memberLogin, setMemberLogin] = useState(false);
  const [systemLogin, setSystemLogin] = useState(false);
  const [systemData, setSystemData] = useState(null);
  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const formattedDate = now.toISOString().split("T")[0].replace(/-/g, "/");
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // 不顯示AM/PM
      };
      const formattedTime = now.toLocaleTimeString([], options);
      setCurrentTime(`${formattedDate} ${formattedTime}`); // 合併日期和時間
    };
    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  const menuItems = [
    { label: "點餐", path: "/order", image: LeftSidebarOrder },
    {
      label: "訂單記錄",
      path: "/order-history",
      image: LeftSidebarHistory,
    },
    {
      label: "備料管理",
      path: "/inventory",
      image: LeftSidebarInventory,
    },
    {
      label: "營業記錄",
      path: "/sales-records",
      image: LeftSidebarSalesRecords,
    },
  ];

  return systemLogin && systemData ? (
    <Grid
      container
      sx={{
        display: "flex",
        flexWrap: "nowrap",
        width: "100%",
        height: "100vh",
      }}
    >
      <Grid
        item
        xs={2}
        className="bg-dark_gray h-full flex w-[178px] h-[100vh]"
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          fullWidth
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
          p={2}
        >
          <Button
            className="w-[141px] h-[58px]"
            key="首頁"
            fullWidth
            size="large"
            onClick={() => navigate("/")}
            sx={{ mb: 2 }}
          >
            <img
              src={systemData.store_image}
              alt="left_sidebar_logo"
              className="w-full h-full object-contain"
            />
          </Button>

          {menuItems.map((item, index) => (
            <Button
              className="max-w-[157px] max-h-[125px]"
              key={item.label}
              fullWidth
              size="large"
              // disabled={!memberLogin}
              onClick={() => navigate(item.path, { state: { systemData } })}
              sx={{
                mb: 2,
                display: "grid",
                borderRadius: 3,
                backgroundColor:
                  location.pathname === item.path ? "#FFF" : "#616161", // 根據當前路由設定背景顏色
                gap: 1,
              }}
            >
              <img
                src={item.image}
                alt={`left_sidebar_btm_${index}`}
                className="max-w-[40px] max-h-[40px] object-contain mx-auto"
              />
              <p
                className={cx(
                  `sidebar_btn_text ${
                    location.pathname === item.path
                      ? "text-card_dark_red font-[700]"
                      : "text-light_gray font-[400]"
                  }`
                )}
              >
                {item.label}
              </p>
            </Button>
          ))}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          fullWidth
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
          p={2}
        >
          <Button
            className="max-w-[157px] max-h-[51px]"
            key="開啟錢櫃"
            fullWidth
            size="large"
            // disabled={!memberLogin}
            onClick={() => alert("開啟錢櫃")}
            sx={{ mb: 2, backgroundColor: "#616161", borderRadius: 3 }}
          >
            <p className="text-light_gray sidebar_btn_text">開啟錢櫃</p>
          </Button>
          {
            <Button
              className="max-w-[157px] max-h-[51px]"
              key="重新開啟"
              fullWidth
              size="large"
              onClick={() => {
                setSystemLogin(false);
              }}
              sx={{
                mb: 2,
                backgroundColor: "#616161",
                borderRadius: 3,
              }}
            >
              <p className="text-light_gray sidebar_btn_text">重新開啟</p>
            </Button>
          }
          <Typography
            sx={{
              width: "134px",
              height: "48px",
              color: "#FFF",
              fontSize: "20px",
              lineHeight: "24.2px",
              textAlign: "center",
              fontWeight: "400",
              letterSpacing: 4,
              marginBottom: 2,
            }}
            align="center"
          >
            {currentTime}
          </Typography>

          {/* {memberLogin && (
            <>
              <Typography
                sx={{
                  width: "134px",
                  color: "#FFF",
                  fontSize: "20px",
                  lineHeight: "24.2px",
                  textAlign: "center",
                  fontWeight: "400",
                  letterSpacing: 4,
                  marginBottom: 2,
                }}
                align="center"
              >
                測試者
              </Typography>
              <Button
                className="max-w-[157px] max-h-[51px]"
                key="登出"
                fullWidth
                size="large"
                onClick={() => {
                  setMemberLogin(false);
                  navigate("/");
                }}
                sx={{
                  backgroundColor: "#616161",
                  borderRadius: 3,
                }}
              >
                <p className="text-light_gray sidebar_btn_text">登出</p>
              </Button>
            </>
          )} */}
        </Box>
      </Grid>

      {/* 右側頁面顯示 */}
      <Grid item xs={10} className="bg-light_gray w-full h-[100vh]">
        <Box>
          {location.pathname === "/" ? (
            <Typography align="center" sx={{ display: "grid" }} p={2}>
              <div className="w-[328px] h-[135px] mx-auto my-[40px]">
                <img
                  src={systemData.store_image}
                  alt="left_sidebar_logo"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="mx-auto">
                <img
                  src={HomePageClassSchedule}
                  alt="left_sidebar_logo"
                  className="w-full h-full object-contain"
                />
              </div>
              {/* {!memberLogin && (
                <Button
                  key="登入"
                  onClick={() => {
                    setMemberLogin(true);
                  }}
                  sx={{
                    mb: 2,
                    backgroundColor: "#FFF",
                    width: 230,
                    height: 107,
                    marginX: "auto",
                    marginY: "36px",
                    borderRadius: 3,
                  }}
                >
                  <p
                    className="text-[#A43B3B]"
                    style={{
                      fontSize: "36px",
                      lineHeight: "42.36px",
                      textAlign: "center",
                      fontWeight: "700",
                      letterSpacing: 4,
                    }}
                  >
                    登入
                  </p>
                </Button>
              )} */}
            </Typography>
          ) : (
            <Outlet />
          )}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <SystemLoginPage
      setSystemLogin={setSystemLogin}
      setSystemData={setSystemData}
    />
  );
}

export default MainMenu;
