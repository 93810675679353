// SalesRecords.jsx
import React from "react";
import { DataGrid } from "@mui/x-data-grid";

function SalesRecords() {
  const columns = [
    { field: "id", headerName: "訂單編號", width: 150 },
    { field: "date", headerName: "日期", width: 150 },
    { field: "customerName", headerName: "顧客姓名", width: 200 },
    { field: "totalAmount", headerName: "總金額", width: 150 },
    { field: "status", headerName: "狀態", width: 150 },
  ];

  const rows = [
    // 从数据源获取订单数据
  ];

  return (
    <div style={{ width: "100%" }}>
      <DataGrid rows={rows} columns={columns} pageSize={10} />
    </div>
  );
}

export default SalesRecords;
