import React, { useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import axios from "axios"; // 导入 axios
import { useNavigate } from "react-router-dom";

function SystemLoginPage(props) {
  const { setSystemLogin, setSystemData } = props;
  const [account, setAccount] = useState(""); // 存储用户名
  const [password, setPassword] = useState(""); // 存储密码
  const [error, setError] = useState(""); // 存储错误信息
  const [loading, setLoading] = useState(false);
  const systemLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log("Submitting login..."); // 调试信息

    try {
      const response = await axios.get(
        `https://apidebug.ciaowin35.com/api/pos/login?account=${account}&password=${password}`
      );
      if (response.data.store_name) {
        setSystemLogin(true);
        setSystemData(response.data);
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      console.error("Login error:", err);
      setError("登入失敗，請檢查您的帳號和密碼");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section
      id="system_login_page"
      className="bg-light_gray h-[100vh] w-[100%] flex justify-center items-center"
    >
      <div
        id="login_form"
        className="bg-dark_gray w-[700px] h-[700px] rounded-3xl p-[48px] grid gap-[32px] justift-center items-center"
      >
        <h3 className="system_login_title">POS登入介面</h3>
        <form
          onSubmit={systemLogin}
          className="flex flex-col items-center w-full"
        >
          <div className="flex w-full items-center justify-center mb-3">
            <label className="system_login_text min-w-[96px]" htmlFor="account">
              帳號
            </label>
            <input
              id="account"
              type="text"
              className="system_login_text text-[#000] text-[24px] w-[320px] p-2 rounded-full border border-gray-300"
              value={account}
              onChange={(e) => setAccount(e.target.value)} // 更新用户名
              required // 设置为必填字段
            />
          </div>

          {/* 密碼輸入框 */}
          <div className="flex w-full items-center justify-center mb-3">
            <label
              className="system_login_text min-w-[96px]"
              htmlFor="password"
            >
              密碼
            </label>
            <input
              id="password"
              type="password"
              className="system_login_text text-[#000] text-[24px] w-[320px] p-2 rounded-full border border-gray-300"
              value={password}
              onChange={(e) => setPassword(e.target.value)} // 更新密码
              required // 设置为必填字段
            />
          </div>
          {error && (
            <Typography color="error" variant="h6">
              {error}
            </Typography>
          )}
          <Button
            type="submit"
            disabled={loading} // Disable button when loading
            sx={{
              mb: 2,
              backgroundColor: "#FFF",
              width: 230,
              height: 107,
              marginX: "auto",
              marginY: "36px",
              borderRadius: 3,
            }}
          >
            <p
              className="text-[#A43B3B]"
              style={{
                fontSize: "32px",
                lineHeight: "42.36px",
                textAlign: "center",
                fontWeight: "700",
                letterSpacing: 4,
              }}
            >
              {loading ? "Loading..." : "登入"}
            </p>
          </Button>
        </form>
      </div>
    </section>
  );
}

export default SystemLoginPage;
