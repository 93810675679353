// OrderHistory.jsx
import React, { useState } from "react";
import {
  Grid,
  Button,
  ButtonGroup,
  TextField,
  Typography,
} from "@mui/material";
import { cx } from "../../utils/cx.ts";

const orderPlaced = [
  {
    name: "蛋糕",
    count: 1,
    unit_price: 150,
    note: "不要香菜",
    tag_arr: [
      {
        title: "加麻加辣",
        price: 0,
      },
      {
        title: "加大",
        price: 50,
      },
      {
        title: "加巧克力碎片",
        price: 100,
      },
    ],
  },
  {
    name: "水餃",
    count: 2,
    unit_price: 150,
    note: "",
    tag_arr: [],
  },
];

function OrderHistory() {
  const [orderType, setOrderType] = useState(null);
  const [startDate, setStartDate] = useState(null); // 开始日期
  const [endDate, setEndDate] = useState(null); // 结束日期
  const [searchQuery, setSearchQuery] = useState(""); // 搜索框输入内容

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleOrderTypeChange = (value) => {
    orderType === value ? setOrderType(null) : setOrderType(value);
  };

  // 計算總金額
  const totalAmount = orderPlaced.reduce(
    (acc, item) =>
      acc +
      item.unit_price * item.count +
      item.tag_arr.reduce((tagAcc, tag) => tagAcc + tag.price, 0),
    0
  );
  const orderTypes = [
    { label: "內用", value: "dine-in" },
    { label: "外帶", value: "takeout" },
    { label: "外送", value: "delivery" },
    { label: "UB", value: "UB" },
    { label: "FP", value: "FP" },
  ];
  const history_type = [
    { title: "訂單", xs: 1 },
    { title: "類型", xs: 1 },
    { title: "付款", xs: 2 },
    { title: "訂購人", xs: 2 },
    { title: "桌單號", xs: 1 },
    { title: "金額", xs: 2 },
    { title: "時間", xs: 3 },
  ];
  const orderList = [
    {
      order_id: "7802",
      type: "內用",
      payment: "尚未結帳",
      status: 1, //0尚未送單、1送單未結帳、2送單已結帳
      name: "李哲瑋",
      order_no: "1桌",
      money: "$600",
      time: "2024/12/12",
    },
    {
      order_id: "7803",
      type: "內用",
      payment: "尚未送單",
      status: 0, //0尚未送單、1送單未結帳、2送單已結帳
      name: "李哲瑋",
      order_no: "2桌",
      money: "$600",
      time: "2024/12/12",
    },
    {
      order_id: "7804",
      type: "內用",
      payment: "現金支付",
      status: 2, //0尚未送單、1送單未結帳、2送單已結帳
      name: "李哲瑋",
      order_no: "4桌",
      money: "$600",
      time: "2024/12/12",
    },
  ];
  return (
    <Grid
      container
      direction="column"
      sx={{ backgroundColor: "#f0f0f0", height: "100vh" }}
    >
      {/* 上方ButtonGroup区域，用于选择订单类型 */}
      <Grid
        container
        spacing={2}
        sx={{
          marginX: "0px !important",
          height: 92,
          alignItems: "center",
          width: "100%",
          padding: "0px 16px",
          gap: "16px",
          flexWrap: "nowrap",
          overflow: "auto",
        }}
        className="hide-scrollbar"
      >
        <Grid item>
          <p className="min-w-[44px] sidebar_btn_text_b text-sold_out_gray text-left">
            篩選
          </p>
        </Grid>
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          {/* 开始日期选择 - 使用原生 input type="date" */}
          <TextField
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputProps={{
              sx: {
                // 这里可以修改 .MuiInputBase-input 的样式
                minWidth: "180px",
                fontSize: "16px",
                color: "#797777",
                backgroundColor: "#FFF",
                height: "48px",
                borderRadius: "9999px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                "&::placeholder": {
                  color: "#C0C0C0", // 自定义占位符颜色
                },
                "&::-webkit-calendar-picker-indicator": {
                  display: "none", // 隐藏日历图标
                },
              },
            }}
          />
          <p className="sidebar_btn_text_b text-sold_out_gray text-left">~</p>
          {/* 结束日期选择 - 使用原生 input type="date" */}
          <TextField
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputProps={{
              sx: {
                // 这里可以修改 .MuiInputBase-input 的样式
                minWidth: "180px",
                fontSize: "16px",
                color: "#797777",
                backgroundColor: "#FFF",
                height: "48px",
                borderRadius: "9999px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                "&::placeholder": {
                  color: "#C0C0C0", // 自定义占位符颜色
                },
                "&::-webkit-calendar-picker-indicator": {
                  display: "none", // 隐藏日历图标
                },
              },
            }}
          />
        </Grid>
        {/* 订单类型选择 ButtonGroup */}
        <Grid item>
          <ButtonGroup
            sx={{
              overflow: "hidden",
              height: "48px",
            }}
            aria-label="Order type selection"
          >
            {orderTypes.map((type) => (
              <Button
                key={type.value}
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  px: 3,
                  border: "1px solid #8E8E8E",
                  borderRadius: 4,
                  borderColor: orderType === type.value ? "#FFF" : "#8E8E8E",
                  backgroundColor:
                    orderType === type.value ? "#FFF" : "#8E8E8E",
                  color: orderType === type.value ? "#A43B3B" : "#FFF",
                }}
                onClick={() => handleOrderTypeChange(type.value)}
              >
                <p className="flex min-w-[36px]">{type.label}</p>
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
        {/* 搜索框 */}
        <Grid item>
          <TextField
            label="搜尋名稱/電話"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              sx: {
                // 这里可以修改 .MuiInputBase-input 的样式
                minWidth: "180px",
                fontSize: "16px",
                color: "#797777",
                backgroundColor: "#FFF",
                height: "48px",
                borderRadius: "9999px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                "&::placeholder": {
                  color: "#C0C0C0", // 自定义占位符颜色
                },
                "&::-webkit-calendar-picker-indicator": {
                  display: "none", // 隐藏日历图标
                },
              },
            }}
            InputLabelProps={{
              sx: {
                minWidth: "180px",
                fontSize: "16px",
                color: "#797777",
                display: "flex",
                alignItems: "center",
                height: "34%",
              },
            }}
          />
        </Grid>
      </Grid>

      {/* 下方区域 */}
      <Grid
        item
        container
        direction="row"
        sx={{
          height: "89%",
          flexGrow: 1,
          display: "flex",
          flexWrap: "nowrap",
          border: "none",
          borderTop: "1px solid #797777",
        }}
      >
        <Grid
          item
          container
          direction="column"
          sx={{
            paddingY: 2,
            paddingX: 2,
            border: "none",
            borderRight: "1px solid #797777",
            height: "100%", // 主容器的高度
            overflow: "auto",
          }}
          className="hide-scrollbar"
          xs={9}
        >
          {/* 標題 */}
          <Grid
            container
            direction="row"
            sx={{
              marginBottom: 1,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              border: "none",
              borderBottom: "1px solid #797777",
            }}
          >
            {history_type.map((type) => (
              <Grid key={type.title} item xs={type.xs}>
                <p className="sidebar_btn_text_b text-sold_out_gray text-left text-center justify-center items-center flex mb-[16px]">
                  {type.title}
                </p>
              </Grid>
            ))}
          </Grid>

          {/* 訂單 */}

          {orderList.map((order) => (
            <Grid
              key={order.order_id}
              container
              direction="row"
              sx={{
                marginBottom: 1,
                background: order.status !== 2 ? "#797777" : "#FFF",
                borderRadius: 4,
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                border: "none",
              }}
            >
              <Grid item xs={1}>
                <p
                  className={cx(
                    `sidebar_btn_text_b text-left text-center justify-center items-center flex my-[16px] h-[28px],
                    ${
                      order.status !== 2 ? "text-[#FFF]" : "text-sold_out_gray"
                    }`
                  )}
                >
                  {order.order_id}
                </p>
              </Grid>
              <Grid item xs={1}>
                <p
                  className={cx(
                    `sidebar_btn_text_b text-left text-center justify-center items-center flex my-[16px] h-[28px],
                    ${
                      order.status !== 2 ? "text-[#FFF]" : "text-sold_out_gray"
                    }`
                  )}
                >
                  {order.type}
                </p>
              </Grid>
              <Grid item xs={2}>
                <p
                  className={cx(
                    `sidebar_btn_text_b text-left text-center justify-center items-center flex my-[16px] h-[28px],
                    ${
                      order.status !== 2
                        ? "text-dark_red"
                        : "text-sold_out_gray"
                    }`
                  )}
                >
                  {order.payment}
                </p>
              </Grid>
              <Grid item xs={2}>
                <p
                  className={cx(
                    `sidebar_btn_text_b text-left text-center justify-center items-center flex my-[16px] h-[28px],
                    ${
                      order.status !== 2 ? "text-[#FFF]" : "text-sold_out_gray"
                    }`
                  )}
                >
                  {order.name}
                </p>
              </Grid>
              <Grid item xs={1}>
                <p
                  className={cx(
                    `sidebar_btn_text_b text-left text-center justify-center items-center flex my-[16px] h-[28px],
                    ${
                      order.status !== 2 ? "text-[#FFF]" : "text-sold_out_gray"
                    }`
                  )}
                >
                  {order.order_no}
                </p>
              </Grid>
              <Grid item xs={2}>
                <p
                  className={cx(
                    `sidebar_btn_text_b text-left text-center justify-center items-center flex my-[16px] h-[28px],
                    ${
                      order.status !== 2 ? "text-[#FFF]" : "text-sold_out_gray"
                    }`
                  )}
                >
                  {order.money}
                </p>
              </Grid>
              <Grid item xs={3}>
                <p
                  className={cx(
                    `sidebar_btn_text_b text-left text-center justify-center items-center flex my-[16px] h-[28px],
                    ${
                      order.status !== 2 ? "text-[#FFF]" : "text-sold_out_gray"
                    }`
                  )}
                >
                  {order.time}
                </p>
              </Grid>
            </Grid>
          ))}
        </Grid>

        {/* 右侧订单详情区域 */}
        <Grid
          item
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
          className="hide-scrollbar"
          xs={3}
        >
          <Grid
            container
            sx={{
              marginBottom: 2,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              paddingX: "8px",
            }}
          >
            <Grid item xs={6} sx={{ paddingLeft: "8px" }}>
              <p className="sidebar_btn_text_b text-sold_out_gray text-left">
                品名
              </p>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p className="sidebar_btn_text_b text-sold_out_gray">數量</p>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p className="sidebar_btn_text_b text-sold_out_gray">單價</p>
            </Grid>
          </Grid>

          {orderPlaced.map((item, index) => (
            <Grid
              direction="row"
              container
              key={index}
              sx={{
                marginBottom: 1,
                backgroundColor: "#FFF",
                borderRadius: "8px",
                padding: "8px",
              }}
            >
              <>
                <Grid item xs={6}>
                  <p className="sidebar_btn_text  text-left">
                    {`${index + 1}.`}
                    {item.name}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p className="sidebar_btn_text">{item.count}</p>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p className="sidebar_btn_text ">${item.unit_price}</p>
                </Grid>
              </>
              {item.tag_arr.length > 0 && (
                <Grid
                  sx={{
                    marginY: "4px",
                    gap: "4px",
                  }}
                  display={"flex"}
                  flexWrap={"wrap"}
                >
                  {item.tag_arr.map((tag) => (
                    <span
                      key={tag.title}
                      className="sidebar_btn_text text-[16px] w-fit flex text-nowrap bg-card_bg_gray rounded-full p-[4px_8px] text-[#FFF]"
                    >{`+ ${tag.title} $ ${tag.price}`}</span>
                  ))}
                </Grid>
              )}
              {item.note && (
                <Grid item xs={12}>
                  <p className="sidebar_btn_text text-ligth_red text-left">{`備註:${item.note}`}</p>
                </Grid>
              )}
            </Grid>
          ))}

          <Grid sx={{ marginTop: "auto" }}>
            <Grid
              direction="row"
              container
              sx={{ marginBottom: 2, fontWeight: "bold" }}
              spacing={2}
            >
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{
                    backgroundColor: "#FFF",
                    height: "52px",
                    borderRadius: 7,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    padding: "8px 16px",
                  }}
                >
                  <p className="text_default_style text-[20px] text-sold_out_gray font-[700]">
                    明細
                  </p>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{
                    backgroundColor: "#FFF",
                    height: "52px",
                    borderRadius: 7,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    padding: "8px 16px",
                  }}
                >
                  <p className="text_default_style text-[20px] text-sold_out_gray font-[700]">
                    標籤
                  </p>
                </Button>
              </Grid>
            </Grid>
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                bgcolor: "#838383",
                height: "64px",
                marginX: "-16px",
                alignItems: "center",
                paddingX: "16px",
              }}
            >
              <p className="text_default_style text-[#FFF]">{`消費金額:`}</p>
              <p className="text_default_style text-[#FFF]">${totalAmount}</p>
            </Typography>
            <Grid
              direction="row"
              container
              sx={{ marginTop: 2, fontWeight: "bold" }}
              spacing={2}
            >
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{
                    backgroundColor: "#FFF",
                    height: "80px",
                    borderRadius: 7,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    padding: "8px 16px",
                  }}
                >
                  <p className="text_default_style text-[#000] font-[700]">
                    刪單
                  </p>
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{
                    backgroundColor: "#FFF",
                    height: "80px",
                    borderRadius: 7,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    padding: "8px 16px",
                  }}
                >
                  <p className="text_default_style text-dark_red font-[700]">
                    提單
                  </p>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrderHistory;
