import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  ButtonGroup,
  Typography,
  Box,
  Card,
  CardContent,
  CardActionArea,
} from "@mui/material";
import ImageIcon from "../../images/image_btn.png"; // 圖片圖標
import PersonIcon from "../../images/member_btn.png";
import TableChartIcon from "../../images/table_btn.png";
import NotesIcon from "../../images/note_btn.png";
import { cx } from "../../utils/cx.ts";
import Member from "../Dialogs/Member.tsx";
import Payment from "../Dialogs/Payment.tsx";
import Commodity from "../Dialogs/Commodity.jsx";
import { useLocation } from "react-router-dom";

const orderPlaced = [
  {
    name: "蛋糕",
    count: 1,
    unit_price: 150,
    note: "不要香菜",
    tag_arr: [
      {
        title: "加麻加辣",
        price: 0,
      },
      {
        title: "加大",
        price: 50,
      },
      {
        title: "加巧克力碎片",
        price: 100,
      },
    ],
  },
  {
    name: "水餃",
    count: 2,
    unit_price: 150,
    note: "",
    tag_arr: [],
  },
];

function OrderPage() {
  const [imgArr, setimgArr] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [point, setPoint] = useState(0);
  const [tableOrOrderNumber, setTableOrOrderNumber] = useState("");
  const [remark, setRemark] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("主食");
  const [commodityData, setCommodityData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState({
    member: false,
    commodity: false,
    payment: false,
  });
  //接收props
  const location = useLocation();
  const { systemData } = location.state || {};

  const filteredMenuItems = () => {
    const meals = systemData.meal.filter(
      (item) => item.category_id === selectedCategory
    );
    const foods = systemData.food.filter(
      (item) => item.category_id === selectedCategory
    );
    return [...meals];
  };
  const renderMenu = () => {
    return systemData.menu
      .filter((menu) => menu.type === null)
      .map((menu) => (
        <Grid
          item
          key={`menu_${menu.category_id}`}
          xs={2.2}
          className="max-h-[143px]"
        >
          <Card
            className="w-full max-h-[143px]"
            sx={{
              aspectRatio: "1/1",
              boxShadow:
                selectedCategory === menu.category_id
                  ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                  : "none",
              backgroundColor:
                selectedCategory === menu.category_id ? "#FFF" : "#8E8E8E",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 4,
            }}
          >
            <CardActionArea
              onClick={() => handleCategoryChange(menu.category_id)}
            >
              <CardContent
                sx={{
                  padding: 1,
                  paddingY: "16px !important",
                }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  sx={{
                    fontWeight:
                      selectedCategory === menu.category_id ? 700 : 400,
                    fontSize: 28,
                    letterSpacing: 4,
                    color:
                      selectedCategory === menu.category_id
                        ? "#A43B3B"
                        : "#E2E2E2",
                  }}
                >
                  {menu.name}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ));
  };
  const renderItems = () => {
    const items = filteredMenuItems();
    return items.map((item) => (
      <Grid item key={item.name} xs={2.2} className="max-h-[143px]">
        <Card
          className="w-full max-h-[143px]"
          sx={{
            aspectRatio: "1/1",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            backgroundColor: item.status === 2 ? "#3C3C3C" : "#8E8E8E",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 4,
          }}
        >
          <CardContent
            sx={{
              paddingX: 1,
              paddingY: "16px !important",
              maxHeight: 143,
            }}
            onClick={() => {
              if (item.status === 1 && item.composition.length > 0) {
                setCommodityData(item);
                handleOpenDialog("commodity");
              }
            }}
          >
            <span
              className={cx(
                `text_default_style grid ${
                  item.status === 2 ? "text-sold_out_gray" : "text-light_gray"
                }`
              )}
            >
              <p
                style={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2, // 限制顯示兩行
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.name}
              </p>
              {`$${item.price}`}
            </span>
          </CardContent>
        </Card>
      </Grid>
    ));
  };

  // 打開彈窗
  const handleOpenDialog = (dialogType) => {
    setDialogOpen((prev) => ({ ...prev, [dialogType]: true }));
  };

  // 關閉彈窗
  const handleCloseDialog = (dialogType) => {
    setDialogOpen((prev) => ({ ...prev, [dialogType]: false }));
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  // 計算總金額
  const totalAmount = orderPlaced.reduce(
    (acc, item) =>
      acc +
      item.unit_price * item.count +
      item.tag_arr.reduce((tagAcc, tag) => tagAcc + tag.price, 0),
    0
  );
  const orderTypes = [
    { label: "內用", value: "dine-in" },
    { label: "外帶", value: "takeout" },
    { label: "外送", value: "delivery" },
    { label: "UB", value: "UB" },
    { label: "FP", value: "FP" },
  ];

  //控制案紐收縮
  const [orderType, setOrderType] = useState(null); // 追踪选中的订单类型
  const [isExpanded, setIsExpanded] = useState(true); // 追踪是否展开

  const handleOrderTypeChange = (value) => {
    if (orderType === value) {
      // 如果再次点击同一个选项，重新展开选择
      setIsExpanded(true);
      setOrderType(null);
    } else {
      // 选择一个选项并收缩
      setOrderType(value);
      setIsExpanded(false);
    }
  };

  return (
    systemData && (
      <Grid
        container
        direction="column"
        sx={{ backgroundColor: "#f0f0f0", height: "100vh" }}
      >
        {/* 上方ButtonGroup区域，用于选择订单类型 */}
        <Grid
          item
          sx={{
            marginX: "0px !important",
            width: "100%",
            overflow: "auto",
            height: 80,
            display: "flex",
            alignItems: "center",
            padding: "0px 16px",
            gap: "16px",
          }}
          className="hide-scrollbar"
        >
          <ButtonGroup
            sx={{
              borderRadius: 4,
              overflow: "hidden",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
            aria-label="Order type selection"
          >
            {isExpanded
              ? orderTypes.map((type) => (
                  <Button
                    key={type.value}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      px: 3,
                      border: "1px solid #8E8E8E",
                      borderRadius: 4,
                      borderColor:
                        orderType === type.value ? "#FFF" : "#8E8E8E",
                      backgroundColor:
                        orderType === type.value ? "#FFF" : "#8E8E8E",
                      color: orderType === type.value ? "#A43B3B" : "#FFF",
                      fontWeight: orderType === type.value ? 700 : 400,
                    }}
                    onClick={() => handleOrderTypeChange(type.value)}
                  >
                    {type.label}
                  </Button>
                ))
              : // 只显示选中的按钮
                orderTypes
                  .filter((type) => type.value === orderType)
                  .map((type) => (
                    <Button
                      variant="outlined"
                      key={type.value}
                      sx={{
                        height: "36px",
                        minWidth: "120px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        px: 3,
                        border: "none",
                        borderRadius: 4,
                        border: "none",
                        backgroundColor: "#FFF",
                        color: "#A43B3B",
                        fontWeight: 700,
                      }}
                      onClick={() => setIsExpanded(true)}
                    >
                      <p className="flex items-center text-[700] text-[20px] leading-6">
                        {type.label}
                      </p>
                    </Button>
                  ))}
          </ButtonGroup>

          <Box sx={{ display: "flex", gap: 2 }}>
            {/* <Button
            variant="outlined"
            sx={{
              backgroundColor: "#FFF",
              borderRadius: 4,
              border: "none",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              gap: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={ImageIcon}
              alt="ImageIcon"
              className="w-[28px] h-[28px]"
            />
            {
              imgArr.length > 0 &&
                imgArr.map((img, index) => {
                  <Box
                    component="img"
                    src={img} // 假设你会用 URL 预览图片
                    alt="Uploaded"
                    key={`order_img_${index}`}
                    sx={{ width: 28, height: 28 }}
                  />;
                })
            }
          </Button> */}

            {/* 会员按钮 */}
            <Button
              variant="outlined"
              sx={{
                height: "36px",
                minWidth: "120px",
                backgroundColor: "#FFF",
                borderRadius: 4,
                border: "none",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                gap: 1,
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                handleOpenDialog("member");
              }}
            >
              <img
                src={PersonIcon}
                alt="ImageIcon"
                className="w-[28px] h-[28px]"
              />
              <p className="sidebar_btn_text_b text-sold_out_gray">
                {memberId ? `${memberId}` : "會員"}
              </p>
            </Button>

            {/* 桌號或單號按钮 */}
            <Button
              variant="outlined"
              sx={{
                height: "36px",
                minWidth: "120px",
                backgroundColor: "#FFF",
                borderRadius: 4,
                border: "none",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                gap: 1,
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                handleOpenDialog("member");
              }}
            >
              <img
                src={TableChartIcon}
                alt="ImageIcon"
                className="w-[28px] h-[28px]"
              />
              <p className="sidebar_btn_text_b text-sold_out_gray">
                {orderType === "dine-in"
                  ? tableOrOrderNumber
                    ? `${tableOrOrderNumber}`
                    : "桌號"
                  : tableOrOrderNumber
                  ? `${tableOrOrderNumber}`
                  : "單號"}
              </p>
            </Button>

            {/* 備註按钮 */}
            <Button
              variant="outlined"
              sx={{
                height: "36px",
                minWidth: "120px",
                backgroundColor: "#FFF",
                borderRadius: 4,
                border: "none",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                gap: 1,
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                handleOpenDialog("member");
              }}
            >
              <img
                src={NotesIcon}
                alt="ImageIcon"
                className="w-[28px] h-[28px]"
              />
              <p className="sidebar_btn_text_b text-sold_out_gray">
                {remark ? `${remark}` : "備註"}
              </p>
            </Button>
            <Button
              variant="outlined"
              sx={{
                height: "36px",
                minWidth: "120px",
                backgroundColor: "#FFF",
                borderRadius: 4,
                border: "none",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                gap: 1,
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                handleOpenDialog("member");
              }}
            >
              <img
                src={PersonIcon}
                alt="ImageIcon"
                className="w-[28px] h-[28px]"
              />
              <p className="sidebar_btn_text_b text-sold_out_gray">
                {point ? `${point} 點` : "點數"}
              </p>
            </Button>
          </Box>
        </Grid>

        {/* 下方区域 */}
        <Grid
          item
          container
          direction="row"
          sx={{
            height: "calc(100% - 80px)",
            flexGrow: 1,
            display: "flex",
            flexWrap: "nowrap",
            border: "none",
            borderTop: "1px solid #797777",
          }}
        >
          {/* 左侧菜单区域 */}
          <Grid
            item
            container
            direction="row"
            sx={{
              paddingY: 2,
              border: "none",
              borderRight: "1px solid #797777",
              height: "100%", // 主容器的高度
            }}
            xs={9}
          >
            {orderType ? (
              <>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    gap: 1,
                    marginX: "auto",
                    height: "40%", // 占据2/5的高度
                    overflow: "auto", // 当内容超出时自动滚动
                  }}
                  className="hide-scrollbar"
                >
                  {renderMenu()}
                </Grid>

                <Grid
                  container
                  spacing={2}
                  sx={{
                    border: "none",
                    borderTop: "1px solid #FFF",
                    margin: "16px 0px",
                    gap: 1,
                    marginX: "auto",
                    height: "60%", // 占据3/5的高度
                    overflow: "auto", // 当内容超出时自动滚动
                  }}
                  className="hide-scrollbar"
                >
                  {renderItems()}
                </Grid>
              </>
            ) : (
              <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
                請選擇用餐方式
              </h1>
            )}
          </Grid>

          {/* 右侧订单详情区域 */}
          <Grid
            item
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
            xs={3}
          >
            <Grid
              container
              sx={{
                marginBottom: 2,
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                paddingX: "8px",
              }}
            >
              <Grid item xs={6} sx={{ paddingLeft: "8px" }}>
                <p className="sidebar_btn_text_b text-sold_out_gray text-left">
                  品名
                </p>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p className="sidebar_btn_text_b text-sold_out_gray">數量</p>
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p className="sidebar_btn_text_b text-sold_out_gray">單價</p>
              </Grid>
            </Grid>

            {orderPlaced.map((item, index) => (
              <Grid
                container
                key={index}
                sx={{
                  marginBottom: 1,
                  backgroundColor: "#FFF",
                  borderRadius: "8px",
                  padding: "8px",
                }}
              >
                <>
                  <Grid item xs={6}>
                    <p className="sidebar_btn_text  text-left">
                      {`${index + 1}.`}
                      {item.name}
                    </p>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p className="sidebar_btn_text">{item.count}</p>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p className="sidebar_btn_text ">${item.unit_price}</p>
                  </Grid>
                </>
                {item.tag_arr.length > 0 && (
                  <Grid
                    sx={{
                      marginY: "4px",
                      gap: "4px",
                    }}
                    display={"flex"}
                    flexWrap={"wrap"}
                  >
                    {item.tag_arr.map((tag) => (
                      <span
                        key={tag.title}
                        className="sidebar_btn_text text-[16px] w-fit flex text-nowrap bg-card_bg_gray rounded-full p-[4px_8px] text-[#FFF]"
                      >{`+ ${tag.title} $ ${tag.price}`}</span>
                    ))}
                  </Grid>
                )}
                {item.note && (
                  <Grid item xs={12}>
                    <p className="sidebar_btn_text text-ligth_red text-left">{`備註:${item.note}`}</p>
                  </Grid>
                )}
              </Grid>
            ))}

            <Grid sx={{ marginTop: "auto" }}>
              <Typography
                variant="h6"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  bgcolor: "#838383",
                  height: "64px",
                  marginX: "-16px",
                  alignItems: "center",
                  paddingX: "16px",
                }}
              >
                <p className="text_default_style text-[#FFF]">{`消費金額:`}</p>
                <p className="text_default_style text-[#FFF]">${totalAmount}</p>
              </Typography>
              <Grid
                container
                sx={{ marginTop: 2, fontWeight: "bold" }}
                spacing={2}
              >
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    sx={{
                      backgroundColor: "#FFF",
                      height: "80px",
                      borderRadius: 7,
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      padding: "8px 16px",
                    }}
                  >
                    <p className="text_default_style text-[#000] font-[700]">
                      送單
                    </p>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    sx={{
                      backgroundColor: "#FFF",
                      height: "80px",
                      borderRadius: 7,
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      padding: "8px 16px",
                    }}
                    onClick={() => handleOpenDialog("payment")}
                  >
                    <p className="text_default_style text-dark_red font-[700]">
                      結帳
                    </p>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Member
          open={dialogOpen.member}
          handleClose={() => handleCloseDialog("member")}
        />
        <Payment
          open={dialogOpen.payment}
          handleClose={() => handleCloseDialog("payment")}
        />
        <Commodity
          open={dialogOpen.commodity}
          handleClose={() => handleCloseDialog("commodity")}
          commodityData={commodityData}
          systemData={systemData}
        />
      </Grid>
    )
  );
}

export default OrderPage;
