import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Card,
  CardContent,
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { cx } from "../../utils/cx.ts";

function Commodity(props) {
  const { open, handleClose, commodityData, systemData } = props;

  const [quantity, setQuantity] = useState(1);
  const [notes, setNotes] = useState("");
  const [selectedFlavor, setSelectedFlavor] = useState("");

  const handleIncrease = () => setQuantity(quantity + 1);
  const handleDecrease = () => setQuantity(quantity > 0 ? quantity - 1 : 0);
  const [clickCounts, setClickCounts] = useState([]); // 用於存儲每張卡片的點擊計數
  const [timeoutIds, setTimeoutIds] = useState({}); // 用於管理每張卡片的計時器
  const handleCardClick = (item) => {
    const itemName = item.name; // 獲取項目名稱
    const categoryId = item.category_id; // 獲取類別 ID
    const pdId = item.pd_id; // 獲取產品 ID

    // 判斷是否存在計時器
    if (timeoutIds[categoryId]) {
      // 如果有計時器，則清除，判定為雙擊
      clearTimeout(timeoutIds[categoryId]);
      setTimeoutIds((prev) => ({ ...prev, [categoryId]: null }));

      // 在這裡處理資料刪除
      setClickCounts((prev) => {
        const updatedCounts = prev.filter(
          (count) =>
            count.categoryId !== categoryId || count.itemName !== itemName
        ); // 根據 categoryId 和 itemName 刪除計數
        // 這裡可以添加邏輯來刪除與 pdId 相關的資料
        return updatedCounts;
      });
    } else {
      // 單擊情況，設置一個計時器來延遲判斷是否是雙擊
      const id = setTimeout(() => {
        setClickCounts((prev) => {
          const existingCount = prev.find(
            (count) =>
              count.categoryId === categoryId && count.itemName === itemName
          );

          if (existingCount) {
            // 如果已存在計數，增加計數
            return prev.map((count) =>
              count.categoryId === categoryId && count.itemName === itemName
                ? { ...count, count: count.count + 1 }
                : count
            );
          } else {
            // 如果不存在，新增計數物件
            return [...prev, { categoryId, itemName, pdId, count: 1 }];
          }
        });
        setTimeoutIds((prev) => ({ ...prev, [categoryId]: null })); // 清除計時器 ID
      }, 300); // 300 毫秒內點擊兩次判定為雙擊
      setTimeoutIds((prev) => ({ ...prev, [categoryId]: id }));
    }
  };

  const renderContent = () => {
    if (!selectedCategory) {
      return (
        <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
          請選擇一個類別
        </h1>
      );
    }

    const filteredFood = systemData.food
      .filter((food) => food.category_id === selectedCategory)
      .sort((a, b) => a.status - b.status);

    return (
      <Grid container direction={"row"} sx={{ gap: "16px" }}>
        {filteredFood.map((item) => (
          <Grid
            item
            key={item.pd_id} // 使用 pd_id 作為鍵以確保唯一性
            xs={2.2}
            className="max-h-[143px]"
            sx={{
              pointerEvents: item.status === 2 ? "none" : "auto",
              cursor: item.status === 2 ? "not-allowed" : "pointer",
              position: "relative",
              ...(clickCounts?.find(
                (count) =>
                  count.categoryId === item.category_id &&
                  count.itemName === item.name
              ) && {
                ".card": {
                  backgroundColor: item.status === 2 ? undefined : "#FFF",
                },
                ".text-content": {
                  color: item.status === 2 ? undefined : "#AC0000",
                  fontWeight: item.status === 2 ? undefined : 700,
                },
              }),
            }}
          >
            <Card
              className="card w-full max-h-[143px]"
              sx={{
                aspectRatio: "1/1",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                backgroundColor: item.status === 2 ? "#3C3C3C" : "#8E8E8E",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 4,
              }}
              onClick={() => handleCardClick(item)}
            >
              <CardContent
                sx={{
                  paddingX: 1,
                  paddingY: "16px !important",
                  maxHeight: 143,
                  transition: "background-color 0.3s ease",
                }}
              >
                <span
                  className={cx(
                    `text_default_style text-content ${
                      item.status === 2
                        ? "text-sold_out_gray"
                        : "text-light_gray"
                    }`
                  )}
                  style={{
                    transition: "color 0.3s ease, font-weight 0.3s ease",
                  }}
                >
                  {item.name}
                  <br />
                  {`$${item.price}`}
                </span>
              </CardContent>

              {/* 右上角顯示點擊次數的計數標籤 */}
              {clickCounts.find(
                (count) =>
                  count.categoryId === item.category_id &&
                  count.itemName === item.name
              ) && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: "#AC0000",
                    color: "#FFFFFF",
                    borderRadius: "50%",
                    width: 24,
                    height: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 700,
                  }}
                >
                  {
                    clickCounts.find(
                      (count) =>
                        count.categoryId === item.category_id &&
                        count.itemName === item.name
                    ).count
                  }
                </Box>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  useEffect(() => {
    console.log(clickCounts);
  }, [clickCounts]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  // 點擊按鈕後處理選擇
  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 8, // 設定對話框的圓角
          backgroundColor: "#E2E2E2", // 設定對話框背景顏色
          padding: 0,
          height: "80%",
          overflow: "auto",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        {/* 上方固定高度的部分 */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "80px",
            borderBottom: "1px solid #ccc",
            padding: "16px 32px",
          }}
        >
          <Grid
            item
            xs={4}
            container
            direction={"row"}
            sx={{ gap: "8px", alignItems: "center" }}
          >
            <p className="sidebar_btn_text_b text-sold_out_gray text-left">
              品項
            </p>
            <Typography
              variant="h6"
              sx={{
                backgroundColor: "#FFF",
                borderRadius: 4,
                height: "48px",
                minWidth: "160px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingX: "24px",
              }}
            >
              <p className="system_login_text text-[24px] tracking-normal text-[#000] font-[700]">
                {`${commodityData?.name}`}
              </p>
            </Typography>
          </Grid>
          <Grid item xs={3} container direction={"row"} sx={{ gap: "8px" }}>
            <IconButton
              onClick={handleDecrease}
              sx={{
                padding: "4px",
                backgroundColor: "#8E8E8E",
                borderRadius: "9999px",
                width: "48px",
                height: "48px",
                ":hover": {
                  backgroundColor: "#8E8E8E",
                },
              }}
            >
              <RemoveIcon />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                backgroundColor: "#FFF",
                borderRadius: 4,
                height: "48px",
                width: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="system_login_text text-[#000] font-[400]">
                {quantity}
              </p>
            </Typography>
            <IconButton
              onClick={handleIncrease}
              sx={{
                padding: "4px",
                backgroundColor: "#8E8E8E",
                borderRadius: "9999px",
                width: "48px",
                height: "48px",
                ":hover": {
                  backgroundColor: "#8E8E8E",
                },
              }}
            >
              <AddIcon />
            </IconButton>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="h6"
              sx={{
                backgroundColor: "#FFF",
                borderRadius: 4,
                height: "48px",
                width: "160px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="system_login_text text-[#000] font-[400]">
                {`$${commodityData?.price * quantity}`}
              </p>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              size="large"
              sx={{
                width: "160px",
                backgroundColor: "#FFF",
                height: "48px",
                borderRadius: "9999px",
                padding: "8px 16px",
              }}
              onClick={() => handleClose()}
            >
              <p className="text_default_style text-dark_red font-[700]">
                送出
              </p>
            </Button>
          </Grid>
        </Box>

        {/* 下方的部分，3:9 的布局 */}
        <Box sx={{ height: "calc(100% - 80px)", overflow: "hidden" }}>
          <Grid container direction={"row"} height={"100%"}>
            {/* 左邊部分，佔 3 */}
            <Grid
              item
              xs={3}
              sx={{
                borderRight: "1px solid #ccc",
                position: "relative",
                height: "100%",
              }}
            >
              <Grid
                item
                container
                direction="column"
                wrap="nowrap"
                sx={{
                  padding: "16px",
                  gap: "16px",
                  height: "calc(100% - 100px)", // 剩下的空间用来放按钮
                  overflowY: "auto", // 超出时滚动
                }}
                className="hide-scrollbar"
              >
                {/* 動態生成按鈕 */}
                {commodityData?.composition.map((compositionItem) => {
                  const menuItem = systemData.menu.find(
                    (menu) => menu.category_id === compositionItem.category_id
                  );
                  return (
                    <Button
                      key={compositionItem.category_id}
                      variant="outlined"
                      onClick={() =>
                        handleCategoryClick(compositionItem.category_id)
                      }
                      sx={{
                        backgroundColor:
                          selectedCategory === compositionItem.category_id
                            ? "#FFF"
                            : "#616161",
                        height: "100px",
                        border: "none",
                        borderRadius: 4,
                        padding: "16px",
                      }}
                    >
                      <p
                        className={cx(
                          "system_login_text text-[24px] tracking-normal font-[700]",
                          selectedCategory === compositionItem.category_id
                            ? "text-[#000]"
                            : " text-[#FFF]"
                        )}
                      >
                        {menuItem ? menuItem.name : "未知分類"}
                      </p>
                    </Button>
                  );
                })}
              </Grid>

              {/* 備註輸入框 固定在底部 */}
              <Grid
                item
                sx={{
                  backgroundColor: "#616161",
                  height: "100px",
                  position: "absolute",
                  display: "flex",
                  gap: "16px",
                  alignItems: "center",
                  bottom: "0px",
                  width: "100%", // 設定佔滿寬度
                  padding: "16px",
                }}
              >
                <p className="min-w-[52px] sidebar_btn_text_b text-[#FFF]">
                  備註
                </p>
                <TextField
                  rows={3}
                  variant="outlined"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  InputProps={{
                    sx: {
                      borderRadius: "9999px", // 設定圓角
                      fontSize: "20px",
                      height: "52px",
                      color: "#797777",
                      fontWeight: 700,
                      backgroundColor: "#FFF",
                      "&::placeholder": {
                        color: "#C0C0C0", // 自定义占位符颜色
                      },
                      "&::-webkit-calendar-picker-indicator": {
                        display: "none", // 隐藏日历图标
                      },
                    },
                  }}
                  sx={{
                    height: "52px",
                    borderRadius: "9999px", // 設定圓角
                    backgroundColor: "white", // 設定背景顏色為白色
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // 去除邊框
                      },
                      "&:hover fieldset": {
                        border: "none", // 去除懸停時邊框
                      },
                      "&.Mui-focused fieldset": {
                        border: "none", // 去除獲得焦點時邊框
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>

            {/* 右邊部分，佔 9 */}
            <Grid item xs={9} sx={{ padding: "16px" }}>
              {renderContent()}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default Commodity;
