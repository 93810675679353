import React, { useState } from "react";
import { Button, TextField, Grid, Typography, Box } from "@mui/material";

export default function Cash(props) {
  const { back } = props;
  const [amount, setAmount] = useState("");
  const [complete, setComplete] = useState(false);
  const handleButtonClick = (value) => {
    setAmount((prevAmount) => prevAmount + value);
  };

  const handlePayment = () => {
    console.log("付款金額:", amount);
    // 在這裡處理付款邏輯

    //未完全支付
    back();

    //完全支付
    setComplete(true);
  };

  const handleClear = () => {
    setAmount("");
  };

  return !complete ? (
    <Box sx={{ paddingX: 2, height: "100%" }}>
      <Grid
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom: 2,
        }}
      >
        <p className="min-w-[80px] sidebar_btn_text_b text-sold_out_gray flex items-top justify-center">
          現金
        </p>
        <TextField
          variant="outlined"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          fullWidth
          disabled
          InputProps={{
            sx: {
              // 这里可以修改 .MuiInputBase-input 的样式
              borderRadius: 4, // 設定圓角
              fontSize: "20px",
              color: "#797777",
              fontWeight: 700,
              backgroundColor: "#FFF",
              "&::placeholder": {
                color: "#C0C0C0", // 自定义占位符颜色
              },
              "&::-webkit-calendar-picker-indicator": {
                display: "none", // 隐藏日历图标
              },
            },
          }}
          sx={{
            borderRadius: 4, // 設定圓角
            backgroundColor: "white", // 設定背景顏色為白色
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "none", // 去除邊框
              },
              "&:hover fieldset": {
                border: "none", // 去除懸停時邊框
              },
              "&.Mui-focused fieldset": {
                border: "none", // 去除獲得焦點時邊框
              },
            },
          }}
        />
      </Grid>

      {/* 數字鍵盤 */}
      <Grid
        container
        spacing={1}
        sx={{ display: "flex", flexWrap: "wrap-reverse" }}
      >
        {Array.from({ length: 10 }, (_, index) => (
          <Grid item xs={index === 0 ? 12 : 4} key={index}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => handleButtonClick(index.toString())}
              sx={{
                height: 60,
                backgroundColor: "#8E8E8E",
                border: "none",
                borderRadius: 2,
                color: "#FFF",
                fontSize: "24px",
              }}
            >
              {index}
            </Button>
          </Grid>
        ))}
      </Grid>

      <Grid container sx={{ marginTop: 2, fontWeight: "bold" }} spacing={2}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={{
              backgroundColor: "#FFF",
              height: "60px",
              borderRadius: 2,
              padding: "8px 16px",
            }}
            onClick={handleClear}
          >
            <p className="text_default_style text-[#000] font-[700]"> 清除</p>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={{
              backgroundColor: "#FFF",
              height: "60px",
              borderRadius: 2,
              padding: "8px 16px",
            }}
            onClick={handlePayment}
          >
            <p className="text_default_style text-dark_red font-[700]"> 付款</p>
          </Button>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <h1 className="system_login_title text-dark_red flex items-center justify-center h-full w-full">
      結帳完成
    </h1>
  );
}
