import React, { useEffect, useRef, useState } from "react";
import { Box, TextField, Button, Grid } from "@mui/material";
import Quagga from "quagga"; // 导入 Quagga 库

const LinePay = (props) => {
  const [data, setData] = useState(""); // 保存扫描结果
  const scannerRef = useRef(null); // 用于视频显示的引用

  useEffect(() => {
    // 请求摄像头权限
    const getCameraPermission = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        console.log("Camera access granted");
      } catch (error) {
        console.error("Camera access denied", error);
      }
    };

    getCameraPermission();

    // 初始化 Quagga
    Quagga.init(
      {
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: scannerRef.current, // 将摄像头流显示在这个 div 中
          constraints: {
            facingMode: "environment", // 后置摄像头
            width: { ideal: 1280 },
            height: { ideal: 720 },
          },
        },
        decoder: {
          readers: ["code_128_reader", "ean_reader", "upc_reader"], // 支持的条形码类型
        },
      },
      (err) => {
        if (err) {
          console.error("Quagga initialization error:", err); // 打印初始化错误
          return;
        }
        console.log("Quagga initialized successfully");
        Quagga.start(); // 启动条码扫描
      }
    );

    // 监听扫描结果
    Quagga.onDetected((result) => {
      console.log("Barcode detected:", result.codeResult.code);
      setData(result.codeResult.code); // 更新输入框的值
    });

    // 在组件卸载时停止 Quagga
    return () => {
      Quagga.stop(); // 停止条码扫描
      console.log("Quagga stopped");
    };
  }, []);

  return (
    <Box
      sx={{
        textAlign: "center",
        padding: "16px",
        height: "100%",
        width: "100%",
      }}
    >
      <p className="mb-[16px] min-w-[80px] sidebar_btn_text text-sold_out_gray tracking-widest">
        LinePay付款
      </p>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "16px",
          marginBottom: "16px",
          overflow: "hidden", // 隐藏超出部分
        }}
      >
        <div
          ref={scannerRef}
          style={{ width: "280px", height: "210px" }} // 使用100%的宽度和高度
        />
        {/* 视频流将显示在此 div 中 */}
      </Box>
      <TextField
        value={data} // 自动更新输入框值
        disabled
        variant="outlined"
        fullWidth
        InputProps={{
          sx: {
            height: "44px",
            borderRadius: "9999px",
            fontSize: "20px",
            color: "#797777",
            fontWeight: 700,
            backgroundColor: "#FFF",
            "&::placeholder": { color: "#C0C0C0" },
            "&::-webkit-calendar-picker-indicator": { display: "none" },
          },
        }}
        sx={{
          borderRadius: "9999px",
          backgroundColor: "white",
          "& .MuiOutlinedInput-root": {
            "& fieldset": { border: "none" },
            "&:hover fieldset": { border: "none" },
            "&.Mui-focused fieldset": { border: "none" },
          },
        }}
      />

      <Grid container sx={{ marginTop: 2, fontWeight: "bold" }} spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            size="large"
            sx={{
              backgroundColor: "#FFF",
              height: "60px",
              borderRadius: 2,
              padding: "8px 16px",
            }}
          >
            <p className="text_default_style text-dark_red font-[700]"> 扣款</p>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LinePay;
