import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainMenu from "./app/MainMenu/MainMenu";
import OrderPage from "./app/OrderPage/OrderPage";
import OrderHistory from "./app/OrderHistory/OrderHistory";
import Inventory from "./app/Inventory/Inventory";
import SalesRecords from "./app/Sales_Records/SalesRecords";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainMenu />}>
          <Route path="/order" element={<OrderPage />} />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/sales-records" element={<SalesRecords />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
